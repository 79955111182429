import React from "react"
import Layout from "../../components/layout"

import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
// import docgen from "../../images/DocgenpeakSUITE.mp4"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const PeaksuiteDocGeneratorSection = loadable(() =>
  pMinDelay(
    import("../../components/peaksuite-sections/PeaksuiteDocGeneratorSection"),
    500
  )
)

const Banner = () => (
  <div>
    <div className="hero-banner peaksuite-wrap-hero">
      <div className="container">
        <div
          className="hero-banner-content text-center"
          style={{ padding: "50px 0px" }}
        >
          <div
            className="hero-title-wrap text-center"
            style={{ background: "#fff" }}
          >
            <ScrollAnimation animateIn="fadeInLeft" initiallyVisible={true}>
              <Link aria-label="link" to="/peaksuite/peaksuite-docgenerator/">
                <StaticImage
                  placeholder="blurred"
                  imgClassName="img-fluid"
                  src="../../../static/assets/productLogos/peaksuite-DU.png"
                  alt="peakSUITE DOCUMENT GENERATOR"
                  width={400}
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  </div>
)
class ProductPeakSuite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "peakSuite | basecamp",
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    localStorage.setItem("title", this.state.title)
  }
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="peakSUITE Doc Generator | Custom Add-Ons for Quickbase"
          description="Easily transfer data from any system or platform into a template before packaging it into a completed file and sending it to storage or email. Learn more."
        />
        <PeaksuiteDocGeneratorSection handleSubmit={this.handleSubmit} />
      </Layout>
    )
  }
}

export default ProductPeakSuite
